import React from 'react'
import { Navigate } from 'react-router-dom'

const Auth = ({children}) => {
  let token = window.sessionStorage.getItem('token');
  if (token) {
    return <>{children}</>
  } else {
    return <Navigate to="/Login" replace></Navigate>
  }
}

export default Auth