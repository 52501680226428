import React from 'react'
import { Layout,Space, Dropdown, Modal, Table, Button, Tabs } from 'antd'
import { useState, useEffect } from 'react';
const { Header, Content, Footer} = Layout
import { useNavigate } from 'react-router-dom';
import { postRequest } from '../utils/common'
import Headers from './header';

const url = '/recharge_record';

const VoucherCenter = () => {
    const navigate = useNavigate();
    const userInfo = JSON.parse(window.sessionStorage.getItem('user'));
    const [tableLoading, setTableLoading] = useState(false);
    const userName = userInfo?.username
    const [userlog, setUserLog] = useState([]);
    const [userfree, setUserFree] = useState([]);

    const getLogData = () => {
        setTableLoading(true);
        return postRequest(url,{ user_name: userName }) 
            .then((response) => {
                // console.log(response.data);
                const data = response?.data || [];
                let tableData = data.filter(item => item.recharge_type === '1')
                let tableData2 = data.filter(item => item.recharge_type === '2')
                // console.log(tableData2);
                setUserLog(tableData)
                setUserFree(tableData2)
                setTableLoading(false);
            });
    }


    const columns = [
        {
            title:'用户名称',
            dataIndex: 'user_name',
            key:'user_name'
        },
        {
            title:'充值金额',
            dataIndex: 'recharge_amount',
            key:'recharge_amount'
        },
        {
            title:'点数',
            dataIndex: 'points',
            key:'points'
        },
        {
            title:'充值时间',
            dataIndex: 'recharge_time',
            key:'recharge_time'
        },
    ]

    const columns1 = [
        {
            title:'店铺数量',
            dataIndex: 'seller_number',
            key:'seller_number'
        },
        {
            title:'充值金额',
            dataIndex: 'recharge_amount',
            key:'recharge_amount'
        },
        {
            title:'充值时间',
            dataIndex: 'recharge_time',
            key:'recharge_time'
        },
        {
            title:'开始时间',
            dataIndex:'service_start_time',
            key:'service_start_time'
        },
        {
            title:'结束时间',
            dataIndex:'service_end_time',
            key:'service_end_time'
        },
        {
            title:'服务名称',
            dataIndex: 'recharge_name',
            key:'recharge_name'
        },
    ]
      
    const items = [
        {
          key: '1',
          label: '充值记录',
          children: <Table loading={tableLoading} dataSource={userlog} rowKey={'recharge_time'} columns={columns} size="middle" scroll={{ x: 'max-content', y: '80%' }}></Table>,
        },
        {
          key: '2',
          label: '基础服务费',
          children: <Table loading={tableLoading} dataSource={userfree} rowKey={'recharge_time'} columns={columns1} size="middle" scroll={{ x: 'max-content', y: '80%' }}></Table>,
        },
    ];

    useEffect(() => {
        getLogData()
    },[])
    
    return  <Layout style={{height:'100vh'}}>
                <Headers></Headers>
                <Content style={{height:'890px'}}>
                    <div className='voucher' style={{height:'100%'}}>
                        <div style={{"marginBottom": "1em","display": "flex","justifyContent": "space-between"}}>
                            <p style={{ fontSize:'2rem', color:'#a1afc9'}}>充值记录</p>
                            <Space>
                                <Button type='primary' onClick={() => getLogData()} >刷新</Button>
                                <Button type='primary' onClick={() => navigate('/home')} >返回首页</Button>
                            </Space>
                        </div>
                        <Tabs defaultActiveKey="1" items={items} onChange={getLogData} />
                    </div>
                </Content>
            </Layout>
}


export default VoucherCenter;