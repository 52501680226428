const pre = '/book_api_v2';

export const isProduction = process.env.NODE_ENV !== 'development';

export const postRequest = (api, data) => {
    return fetch(`${pre}${api}`, {
       method: 'POST',
       body: JSON.stringify(data),
       headers: {
          'Content-type': 'application/json; charset=UTF-8',
       },
    })
    .then(rep => {
        if (rep.status === 200) {
            return rep.json()
        } else {
            return Promise.reject(rep)
        }
    })
    .catch((err) => {
        //console.log(err.message);
        return Promise.reject(err)
    });
};
export const getRequest = (api) => {
    return fetch(`${pre}${api}`, {
       method: 'GET',
       headers: {
          'Content-type': 'application/json; charset=UTF-8',
       },
    })
    .then(rep => {
        if (rep.status === 200) {
            return rep.json()
        } else {
            return Promise.reject(rep)
        }
    })
    .catch((err) => {
        //console.log(err.message);
        return Promise.reject(err)
    });
};
