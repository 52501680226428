import React from "react";
import dayjs from 'dayjs';
import { Checkbox, Tag, Modal, Input, Space, DatePicker } from 'antd';
import { useState } from 'react';
const { RangePicker } = DatePicker;

const weeklyOptions = ['周一', '周二', '周三', '周四', '周五', '周六', '周日'];
const TimeOptions = ['06:00-07:00', '07:00-08:00', '08:00-09:00', '09:00-10:00', '10:00-11:00', '11:00-12:00', '12:00-13:00', '13:00-14:00', '14:00-15:00', '15:00-16:00', '16:00-17:00', '17:00-18:00', '18:00-19:00', '19:00-20:00', '20:00-21:00', '21:00-22:00', '22:00-23:00'];
const specialOptions = ['排除节假日'];
const dateFormat = 'YYYY/MM/DD';

const RuleModal = (props) => {
    const isModalOpen = props.open;
    const data = props.data;
    if (!data) return;
    const isDefault = data.name === '默认规则';
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [hourChecked, setChecked] = useState(() => data.inHours?.enabled);
    const [hourValue, setHourValue] = useState(() => data.inHours?.value);
    const [ruleName, setRuleName] = useState(() => data.name);
    const [weekly, setWeeklySetting] = useState(() => data.weekly);
    const [TimeRanges, setTimeRangesSetting] = useState(() => data.TimeRanges);
    const [special, setSpecialSetting] = useState(() => data.special);
    const [startTime, setStartTime] = useState(() => data.start_time ? data.start_time : dayjs() );
    const [stopTime, setStopTime] = useState(() => data.stop_time ? data.stop_time : dayjs() );

    const saveRule = () => {
        const rule = {
            name: ruleName,
            weekly,
            TimeRanges,
            special,
            start_time: startTime,
            stop_time: stopTime
        };
        props.saveRule(rule);
    };

    const onHourChange = (e) => {
        setChecked(e.target.checked);
    };

    const onRuleNameChange = (e) => {
        setRuleName(e.target.value)
    }

    const onWeeklyChange = (list) => {
        setWeeklySetting(list)
    }

    const onTimeRangesChange = (list) => {
        setTimeRangesSetting(list)
    }

    const onSpecialChange = (list) => {
        setSpecialSetting(list)
    }

    const handleOk = () => {
        setConfirmLoading(true);
        const rule = {
            ruleId: data.ruleId,
            name: ruleName,
            weekly,
            TimeRanges,
            special,
            start_time: startTime,
            stop_time: stopTime
        };
        props.saveRule(rule).then(() => {
            props.onClose();
            setConfirmLoading(false);
        }).catch(() => {
            setConfirmLoading(false);
        })
    };
    
    const handleCancel = () => {
        props.onClose();
    };

    const onDateChange = (date, dateString) => {
        setStartTime(dateString[0]);
        setStopTime(dateString[1])
    }

    return (
        <Modal  open={isModalOpen} onOk={handleOk} onCancel={handleCancel} destroyOnClose={true} confirmLoading={confirmLoading} cancelText={"取消"} okText={"保存规则"} width={600}>
            <Space direction="vertical">
                <div className="modal-save-rule">
                    <Space>
                        <label style={{"fontWeight": "800"}}>规则名称：</label>
                        <Input  size="small" placeholder="规则名称" defaultValue={ruleName} onChange={onRuleNameChange} disabled={true}/>
                    </Space>
                </div>
                <br/>
                <div>
                    <Space>
                        <label style={{"fontWeight": "800"}}>预约日期范围：</label>
                        <RangePicker  onChange={onDateChange} defaultValue={[dayjs(startTime, dateFormat), dayjs(stopTime, dateFormat)]} disabled={confirmLoading}/>
                    </Space>
                </div>
                <br/>
                <div>
                    <Space direction="vertical">
                    <label style={{"fontWeight": "800"}}>期望周几约到：</label>
                        <Checkbox.Group options={weeklyOptions} value={weekly} onChange={onWeeklyChange} disabled={confirmLoading}/>
                        <br/>
                        <label style={{"fontWeight": "800"}}>期望预约时间段：</label>
                        <Checkbox.Group className='checkboxes0' options={TimeOptions} value={TimeRanges} onChange={onTimeRangesChange} disabled={confirmLoading}/>
                        <br/>
                        <label style={{"fontWeight": "800"}}>特殊规则设置：</label>
                        <div>
                        <Checkbox.Group options={specialOptions} value={special} onChange={onSpecialChange} disabled={confirmLoading}/>
                        <Tag>2023-09-16</Tag><Tag>2023-11-20</Tag><Tag>2023-12-25</Tag>
                        <Tag>2024-01-01</Tag><Tag>2024-02-05</Tag><Tag>2024-03-18</Tag>
                        <Tag>2024-05-01</Tag><Tag>2024-06-02</Tag><Tag>2024-09-16</Tag>
                        <Tag>2024-10-01</Tag><Tag>2024-11-18</Tag><Tag>2024-12-25</Tag>
                        </div>
                        <br/>
                        <label>Note: 上述时间都为当地时间</label>
                    </Space>
                </div>
            </Space>
        </Modal>
    )
}
  
export default RuleModal
