import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './main/index';
import Login from './login/login';
import Auth from './main/auth'
import VoucherCenter from './main/voucherCenter';

export function App() {
    return <BrowserRouter>
        <div>
            <Routes>
                <Route path="/login" element={<Login />}></Route>
                <Route path="/home" element={<Auth><Home /></Auth>} />
                <Route path="/voucher" element={<Auth><VoucherCenter /></Auth>} />
                <Route path="*" element={<Login />} />
            </Routes>
        </div>
    </BrowserRouter>;
}



export default App;