import React from 'react'
import { Layout,Space, Dropdown, Modal, Table, Button } from 'antd'
import { useState, useEffect } from 'react';
const { Header, Content, Footer} = Layout
import { DownOutlined } from '@ant-design/icons';
import { postRequest } from '../utils/common'

const url = '/get_charge_log';

const Headers = () => {
    const user = JSON.parse(window.sessionStorage.getItem('user'));

    const sellers = JSON.parse(window.sessionStorage.getItem('sellers'))?.id;
    const [userInfo, setUserInfo] = useState(user);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [points, setPoints] = useState(user.points);
    const [userLog, setUserLog] = useState([]); 
    const [filters, setFilters] = useState([]);
    const isArrears = userInfo?.points <= 0 || false;
    const logout = () => { 
        window.sessionStorage.removeItem('token');
        window.sessionStorage.removeItem('user');
        window.sessionStorage.removeItem('sellers');
        // navigate('/login');
        window.location.href = '/login';
    };

    const getLogData = () => {
        return postRequest(url, {
            seller_id: sellers
        }).then((response) => {
            console.log('stop_response-----'+ JSON.stringify(response));
            const data = response?.data || [];
            const tableData = data.filter(item => !!item.seller_name )
            const filters = Array.from(new Set(tableData.map(item => item.seller_name)))
            .map(item => ({
                text:item,
                value:item
            }))
            .sort((a, b) => {
                const numA = parseInt(a.text.replace('seller', ''));
                const numB = parseInt(b.text.replace('seller', ''));
                return numA - numB;
            })
            setFilters(filters)
            setUserLog(tableData);
            setIsModalOpen(true);

        });
    }

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onClick = ({ key }) => {
        switch(key) {
            case '1':
                logout();
                break;
            case '2':
                getLogData();
                break;
            default:
                break;
        }
    };

    const updateInfo = (count, points) => {
        setPoints(points)
        setSucessCount(count)
    }
      
    const items = [
        {
            label: '登出',
            key: '1',
        },
        // {
        //     label: '查看预约日志',
        //     key: '2',
        // }
    ];

    const columns = [
        {
            title: '序号',
            dataIndex: 'serial_number',
            sorter: (a, b) => a.serial_number - b.serial_number,
            defaultSortOrder: 'ascend'
            //width: 90
        },
        {
            title: '店铺名称',
            dataIndex: 'seller_name',
            filters: filters,
            onFilter: (value, record) => record.seller_name === value,
            sorter: (a, b) => a.user_name - b.user_name
        },
        {
            title: '店铺编号',
            dataIndex: 'seller_id',
            sorter: (a, b) => a.seller_id - b.seller_id,
           // width: 90
        },
        {
            title: '货件编号',
            dataIndex: 'inbound_id',
            sorter: (a, b) => a.inbound_id - b.inbound_id
        },
        {
            title: '扣除点数',
            dataIndex: 'deduction_point',
            sorter: (a, b) => a.deduction_point - b.deduction_point,
            //width: 90
        },
        {
            title: '剩余点数',
            dataIndex: 'balance',
            sorter: (a, b) => a.balance - b.balance,
            render: (balance) => (
                    <span>{balance / 10}</span>
            ),
            //width: 90
        },
        {
            title: '扣点时间',
            dataIndex: 'charge_time',
            sorter: (a, b) => a.charge_time - b.charge_time,
            //width: 90
        },
        
    ];

    return <Header style={{"display": "flex",
                    "aignItems": "center",
                    "color": "#ccc",
                    "justifyContent": "space-between"}}>
                    <div style={{"display": "flex",
                        "aignItems": "center"}}>
                        <div className="logo" />
                        <div style={{"fontSize":"1.5em", 'cursor': 'pointer'}} onClick={() => window.location.href = '/home'}>捷豹约仓助手 - 美客多版</div>
                    </div>
                    <div style={{"display": "flex", "alignItems": "center"}}>
                        <a href="/voucher">充值记录</a>
                        <a href='#' style={{'margin':'0 1em'}} onClick={() => getLogData() }>预约记录</a>
                        <Space><div>已成功预约{userInfo.sucessCount}次，剩余点数：{points <= 0 ? '您当前的点数不足，请联系客服人员充值！微信号/手机号: 18626896818  ' : points }</div>
                        <Dropdown menu={{ items, onClick }}>
                            <a onClick={(e) => e.preventDefault()}>
                                <Space>
                                    {userInfo.username}
                                    <DownOutlined />
                                </Space>
                            </a>
                        </Dropdown>
                        <Modal title="预约记录" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}  width={1000}>
                            <br/>
                            <Table columns={columns} dataSource={userLog} scroll={{  y: '800px' }}/>
                        </Modal>
                        </Space>
                    </div>
                </Header>
}
export default Headers;