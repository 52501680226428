import React from "react";
import { Select, Button, Modal, Space } from 'antd';
import { useState } from 'react';


const AddressModal = (props) => {
    const [current, setCurrent] = useState(props.current);

    const data = props.data || {
        ftl: [],
        pickup: [
            {id: 1234, name: 'street 1 , city 1, state 1, country 1'},
            {id: 2234, name: 'street 1 , city 1, state 1, country 2'},
            {id: 3234, name: 'street 1 , city 1, state 1, country 3'}
        ],
        supplier: [{id: 1234, name: 'street 1 , city 1, state 1, country 1'},
        {id: 2234, name: 'street 1 , city 1, state 1, country 4'},
        {id: 3234, name: 'street 1 , city 1, state 1, country 5'}]
    }
    const provinceData = Object.keys(data);
    const cityData = provinceData.reduce((res, key) => {
        res[key] = data[key].map(item => `${item.name}--${item.id}`);
        return res;
    }, []);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [type, setType] = useState(provinceData[0]);
    const [cities, setCities] = useState(cityData[provinceData[0]]);
  const [secondCity, setSecondCity] = useState(cityData[provinceData[0]][0]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
};

const onClose = () => {
    setIsModalOpen(false);
}
const shouldDisabled = props.applying || props.status?.status !== 'Idle';
  const handleProvinceChange = (value) => {
    setType(value)
    setCities(cityData[value]);
    setSecondCity(cityData[value][0]);
  };
  const onSecondCityChange = (value) => {
    setSecondCity(value);
  };
    const handleOk = () => {
        setConfirmLoading(true);
        const data = {
            type: type,
            
        };
        if (secondCity) {
            Object.assign(data, {
                address: {id: secondCity.split('--')[1], name: secondCity.split('--')[0]}
            })
        }

        props.saveAddress(data).then((res) => {
            setCurrent(res.response.current)
            setIsModalOpen(false);
            setConfirmLoading(false);
        }).catch(() => {
            setConfirmLoading(false);
        })

    };

    const coverValue = (value) => {
        if (value === 'pickup') {
            return 'Quiero la colecta a domicilio (上门取件)'
        } else if (value === 'ftl' ) {
            return 'Iré en un vehículo particular (送货到提货点)'
        } else if (value === 'supplier' ) {
            return 'Lo enviará mi proveedor (我的供应商将会发货)'
        }
        return value;
    }
    
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <div>
            <Button size="small" onClick={showModal} disabled={shouldDisabled}>设置货运信息</Button>
            <br/>
            <label>{coverValue(current?.type)} </label>
            <br/>
            <label>{current?.address?.name} - {current?.address?.id} </label>
            <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel} destroyOnClose={true} confirmLoading={confirmLoading} cancelText={"取消"} okText={"保存"} width={600}>
                <label style={{"fontWeight": "800", "fontSize": "17px"}}>设置货运信息：</label>
                <Space wrap>
                    <div>
                        <label>取货方式：</label>
                        <Select
                            defaultValue={provinceData[0]}
                            style={{width: 320}}
                            onChange={handleProvinceChange}
                            options={provinceData.map((province) => ({
                                label: coverValue(province),
                                value: province,
                            }))}/>
                    </div>
                    <div>
                        <label>地址信息：</label>
                            <Select
                                disabled={cities.length === 0}
                                style={{width:320}}
                                value={secondCity}
                                onChange={onSecondCityChange}
                                options={cities.map((city) => ({
                                    label: city,
                                    value: city
                                }))}/>
                    </div>
                </Space>
            </Modal>
        </div>
        
    )
}
  
export default AddressModal