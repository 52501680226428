import React from 'react'
import { Table } from 'antd';
import { RedoOutlined,SettingOutlined,SendOutlined, InfoCircleOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { Select , Divider , Tag, Checkbox , Input, Modal, Tooltip } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Space, Input } from 'antd';
import { useState, useEffect , useRef } from 'react';
import Apply from './apply';
import Setting from './setting';
import { DownOutlined, SmileOutlined, ClearOutlined } from '@ant-design/icons';
import { isProduction, postRequest, getRequest } from '../utils/common'
import AddressModal from './addressModal'; 
import RuleModal from './ruleModal'
import { Scatter } from '@ant-design/plots';

const pre = '/book_api_v2';
const URI = {
    LOGIN: '/login',
    INBOUND: '/get_inbounds',
    APPLY: '/apply',
    SET_RULE: '/set_rule',
    GET_RULE: '/get_rule',
    GET_STATUS: '/get_status',
    APPLY_STOP: '/apply_stop',
    SET_ADDRESS: '/set_address',
    GET_CHARGE_LOG: '/get_charge_log',
    FILTER_STATUS: '/filter_status',
    EXPORT_TABLE: '/export_inbounds_skus',
};

const statusValues = [{
    label: '已约成功(平台)',
    value: 'S-1',
  }, {
    label: '已约成功(捷豹)',
    value: 'S-2',
  }, {
    label: '预约中',
    value: 'Ongoing',
  }, {
    label: '待预约',
    value: 'Idle',
}];

const statusKeys = ['S-1', 'S-2', 'Ongoing', 'Idle'];

const Main = (props) => {
    const userInfo = JSON.parse(window.sessionStorage.getItem('user'));
    const sellerInfo = JSON.parse(window.sessionStorage.getItem('sellers'));
    const token = window.sessionStorage.getItem('token');
    // console.log("token", token);
    // console.log(sellerInfo);
    const userId = userInfo?.id;
    const username = userInfo?.username;
    const seller_id = sellerInfo?.id || [];
    const allNames = sellerInfo?.name || [];
    const sellerOptions = seller_id?.map((id, index) => {
        return {
            label: allNames[index],
            value: id
        }
    });
    const dpOptions = [{
        label: '全部店铺',
        value: 'all'
    }, ...sellerOptions];
    const [tableLoading, setTableLoading] = useState(false);
    const [selectedSellers, setSelectedSellers] = useState(seller_id);
    const [inbounds, setInbounds] = useState([]);
    const [inboundsFilter, setInboundsFilter] = useState([]);
    const [filterValue, setFilterValue] = useState('');
    const [inboundStatus, setInboundStatus] = useState([]);
    const [bookSuccessCount, setBookSuccessCount] = useState(null);
    const [points, setPoints] = useState(null);
    const [defaultRule, setDefaultRule] = useState(undefined);
    const [forceUpdating, setForceUpdating] = useState(false);
    const [applyAllOngoing, setApplyAllOngoing] = useState(false);
    const [applyings, setApplyings] = useState({});
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRule, setSelectedRule] = useState({});
    const [isRuleModalOpen, setIsRuleModalOpen] = useState(false);
    const [state, updateState] = useState();
    const [statusList, setStatusList] = useState(statusKeys);
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [ids, setIds] = useState([])
    const [exportLoading, setExportLoading] = useState(false);
   
    const showModal = () => {
      setIsModalOpen(true);
    };
  
    const handleOk = () => {
      setIsModalOpen(false);
    };
  
    const handleCancel = () => {
      setIsModalOpen(false);
    };

    const getTimeRange = (time) => {
        switch(time) {
            case '06':
                return '06:00-07:00';
            case '07':
                return '07:00-08:00';
            case '08':
                return '08:00-09:00';
            case '09':
                return '09:00-10:00';
            case '10':
                return '10:00-11:00';
            case '11':
                return '11:00-12:00';
            case '12':
                return '12:00-13:00';
            case '13':
                return '13:00-14:00';
            case '14':
                return '14:00-15:00';
            case '15':
                return '15:00-16:00';
            case '16':
                return '16:00-17:00';
            case '17':
                return '17:00-18:00';
            case '18':
                return '18:00-19:00';
            case '19':
                return '19:00-20:00';
            case '20':
                return '20:00-21:00';
            case '21':
                return '21:00-22:00';
            case '22':
                return '22:00-23:00';
            default:
                return '';
        }
    }

    const onFilterValueChange = (e) => {
        // console.log(e);
        setFilterValue(e.target.value);
        filterTableById(e.target.value);
        setStatusList(statusKeys);
    }

    const onStatusFilterChange = (list) => {
        setStatusList(list);
        filterTableByStatus(list);
        setFilterValue('');
    }

    const showRuleModal = () => {
        setIsRuleModalOpen(true)
    }

    const exportTableJSON = () => {
        // console.log("导出货件SKU信息", username);
        setExportLoading(true)
        window.location.href = `${pre}${URI.EXPORT_TABLE}?username=${username}&token=${token}`;
        setTimeout(() => {
            setExportLoading(false)
        },14000)
    }

    const filterTableByStatus = (keys) => {
        postRequest(URI.FILTER_STATUS,{
            seller_id,status:keys
        }).then(res => {
            const formatedData = res?.data?.inbounds.map(formatInbound);
            setInboundsFilter(formatedData)
        } )
        // const data1 = inbounds?.filter(item => {
        //     const obj = getStatus(item?.inboundId);
        //     console.log(obj,'55555555555');
        //     return keys.includes(obj?.status)
        //         || (keys.includes('S-1') && obj?.status === 'Succeeded' && !item.successTime) 
        //         || (keys.includes('S-2') && obj?.status === 'Succeeded' && !!item.successTime)
        //         || (keys.includes('Idle') && obj?.status === 'Succeeded' && !item.successTime) 
        // });
        // const data2 = inbounds?.filter(item => {
            
        // });
        // console.log(data1,'111111111');
        // setInboundsFilter([...data1])
    }

    const filterTableById = (key) => {
        if (!key) {
            setInboundsFilter(inbounds);
        } else {
            const data = inbounds?.filter(item => {
                return String(item?.inboundId).includes(key);
            })
            setInboundsFilter([...data])
        }
    }

    const clearFilter = () => {
        setInboundsFilter(inbounds)
        setFilterValue('')
        filterTableById('')
    }

    const onRuleClose = () => {
        setIsRuleModalOpen(false);
    }

    const saveAddress = (cur, data) => {
        return postRequest(URI.SET_ADDRESS, Object.assign(data, {
            inboundId: cur.inboundId
        })).then((response) => {
            return {
                response
            }
        }).catch((err) => {

            return Promise.reject(err)
        });
    };

    const handleDpChange = (value) => {
        let data = []
        if (value === 'all') {
            data = seller_id;
        } else {
            data = [value];
        }
        setSelectedSellers(data);
        get_inbounds(false, data);
    };

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
      };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        selections: [],
        getCheckboxProps: (record) => ({
            disabled: !['Idle', 'Edle'].includes(inboundStatus[record.inboundId]?.status)  || hasOperationOngoing(),
        })
    }

    const hasOperationOngoing = () => {
        return Object.values(applyings).findIndex(value => value) > -1
    }

    const get_inbounds = (force = false, sellers = seller_id) => {
        setTableLoading(true);
        postRequest(URI.INBOUND, {
            seller_id: sellers,
            force_update: force,
        }).then(data => {
            setForceUpdating(false);
            const inbounds = data?.data?.inbounds || [];
            setDefaultRule(inbounds[0]?.global_rule)
            const formatedData = inbounds.map(formatInbound);
            setInbounds(formatedData);
            setIds(formatedData.slice(0,10).map(item => item.inboundId))
            setInboundsFilter(formatedData);
            setStatusList(statusKeys);
            setTableLoading(false);
        });
    }

    const get_status = (ids) => {
        if(ids.length === 0){ return }
        // fetch status
        postRequest(URI.GET_STATUS, {
            seller_id,ids
        }).then(data => {
            const inbounds = data?.inbounds || [];
            const book_success_count = data?.book_success_count;
            const points = data?.points;
            const formated = inbounds?.reduce((res, cur) => {
                res[cur.id] = {
                    begin_book_time: cur.begin_book_time,
                    history_miss_time: cur.history_miss_time,
                    status: cur.status,
                    history_time: cur.history_time
                }
                return res;
            }, []);
            setInboundStatus(formated);
            setBookSuccessCount(book_success_count);
            setPoints(points);
            props.updateInfo(book_success_count, points);
        });
    }

    const forceUpdated = () => {

        setForceUpdating(true);
        updatePlanList(true);
    }
 
    const updatePlanList = (flag) => {
        // fetch status
        // get_status();
        // fetch inbounds
        get_inbounds(flag, seller_id)
    }

    useEffect(() => {
        updatePlanList(false);

        // const Interval = setInterval(() => {
        //     get_status();
        // }, 20000);
        // return () => {
        //     clearInterval(Interval)
        // }
    }, []);
    useEffect(() => {
        get_status(ids);
        const Interval = setInterval(() => {
            get_status(ids);
        }, 3000);
        return () => {
            clearInterval(Interval)
        }
    },[ids])

    useEffect(()=> {
        setIds(inboundsFilter.slice(0,10).map(item => item.inboundId))
    },[inboundsFilter])

    const getDefaultRuleType = (id) => {
        const index = inbounds?.findIndex(inbound => {
            return inbound?.inboundId === id;
        });
        return inbounds[index]?.rule_type;
    }

    const shouldApplyAllEnable = () => {
        const availableInbounds = selectedRowKeys.map(key => {
            const arr = key?.split('-');
            return {
                id: Number(arr[2])
            };
        }).filter(key => {
            return inboundStatus[key.id]?.status === 'Idle';
        });
        return availableInbounds.length === 0;
    }

    const applySelect = () => {
        // APPLY ALL
        setApplyAllOngoing(true);
        const availableInbounds = selectedRowKeys.map(key => {
            const arr = key?.split('-');
            return {
                id: Number(arr[2])
            };
        }).filter(key => {
            return inboundStatus[key.id]?.status === 'Idle';
        });
        availableInbounds.forEach(key => {
            setApplyings((preApplyings) => {
                preApplyings[key.id] = true;
                return preApplyings;
            });
        });
        forceUpdate()
        const inbounds = availableInbounds.map((key) => {
            return {
                id: key.id,
                rule_type: selectedRule[key.id] || getDefaultRuleType(key.id),
                seller_id: seller_id
            }
        })
        postRequest(URI.APPLY, {
            inbounds,
            seller_id: seller_id
        }).then((response) => {
            setApplyAllOngoing(false);
            const inbounds = response?.data?.inbounds || [];
            setInboundStatus(preList => {
                const newApplyings = [...preList];
                inbounds.forEach((inb) => {
                    newApplyings[inb.id] = inb;
                });
                return newApplyings;
            });

            availableInbounds.forEach(id => {
                setApplyings((preApplyings) => {
                    preApplyings[id] = false;
                    return preApplyings;
                });
            });
        }).catch(() => {
            setApplyAllOngoing(false);
        });
    }

    const applyStop = (cur) => {
        setApplyings((preApplyings) => {
            preApplyings[cur.inboundId] = true;
            return preApplyings;
        });
        return postRequest(URI.APPLY_STOP, {
            seller_id: seller_id,
            inboundId: cur.inboundId
        }).then((response) => {
            const inbounds = response?.data?.inbounds || [];
            setInboundStatus(preList => {
                const newApplyings = [...preList];
                inbounds.forEach((inb) => {
                    newApplyings[inb.id] = inb;
                });
                return newApplyings;
            });

            setApplyings(preApplyings => {
                preApplyings[cur.inboundId] = false;
                return preApplyings;
            });
        });
    }

    const applyStart = (cur) => {
        setApplyings((preApplyings) => {
            preApplyings[cur.inboundId] = true;
            return preApplyings;
        });
        forceUpdate();
        return postRequest(URI.APPLY, {
            inbounds: [
                {
                    id: cur.inboundId,
                    rule_type: selectedRule[cur.inboundId] || cur.rule_type,
                    seller_id: cur.seller_id
                }
            ],
            seller_id: seller_id

        }).then((response) => {
            const inbounds = response?.data?.inbounds || [];
            setInboundStatus(preList => {
                const newApplyings = [...preList];
                inbounds.forEach((inb) => {
                    newApplyings[inb.id] = inb;
                });
                return newApplyings;
            });

            setApplyings(preApplyings => {
                preApplyings[cur.inboundId] = false;
                return preApplyings;
            });
        });
    }

    const formatInbound = (item, index) => {
        return {
            key: `${index + 1}-${item.seller_id}-${item.id}`,
            inboundId: item.id,
            seller_id: item.seller_id,
            rule_type: item.rule_type,
            rules: [
                item.global_rule,
                item.self_rule
            ],
            last_updated: item.last_updated,
            on_sale_units: item.on_sale_units,
            products_count: item.products_count,
            reception_date: item.reception_date,
            user_name: item.user_name,
            status: item.status,
            successTime: item.success_time,
            addressInfo: item.addressInfo,
            current: item.current,
            appointment: item.appointment,
            point: item.point
        }
    }

    const setRule = (value, inboundId) => {
        const data = {
            user_id: userId,
            rules: value,
            seller_id: selectedSellers,
            all_seller: seller_id
        };
        if (inboundId) {
            Object.assign(data, {
                inbound_id: inboundId
            });
        }
        return postRequest(URI.SET_RULE, data).then((response) => {
            const formatedData = response?.data?.inbounds.map(formatInbound);
            setInbounds(formatedData);
            setInboundsFilter(formatedData);
            setStatusList(statusKeys);

            return {
                status: 'Succeeded'
            };
        }).catch((err) => {
            return Promise.reject(err)
        });
    }

    const setRuleType = (value, inboundId, ruleType) => {
        const data = {
            user_id: userId,
            rules: value,
            seller_id: selectedSellers,
            all_seller: seller_id,
            rule_type: ruleType,
            inbound_id: inboundId
        };
        return postRequest(URI.SET_RULE, data).then((response) => {
            return {
                status: 'Succeeded'
            };
        }).catch((err) => {
            return Promise.reject(err)
        });
    }

    const getStatus = (id) => {
        return inboundStatus?.[id];
    }

    const selectRule = (type, inboundId) => {
        setSelectedRule((preSelected) => {
            preSelected[inboundId] = type;
            return preSelected;
        });
        if (type === 1) {
            setRuleType(defaultRule, inboundId, type);
        }
        forceUpdate();
    }

    const getCoverdStatus = (status, appointment) => {
        if (appointment && appointment.appointment_expired === true) {
            return `预约已逾期 ${getDate(appointment?.date)} ${getTime(appointment?.date)}`;
        } else if (!!appointment) {
            return `预约成功 ${getDate(appointment?.date)} ${getTime(appointment?.date)}`
        } else if (status === 'working') {
            return '待预约';
        } else if (status && status.includes('closed')) {
            return '预约成功';
        } else if (status === 'cancelled') {
            return '货单已取消';
        } else if (status === 'confirmed') {
            return '待预约';
        } else return status;
    }

    const getDate = (date) => {
        try {
            return date.split('T')[0];
        } catch(e) {
            return ''
        }
    }

    const getTime = (date, index = 0) => {
        try {
            return date.split('T')[1]?.split('-')?.[index];
        }catch(e) {
            return null
        }
    }

    const getApplyStatus = (id) => {
        return applyings[id];
    }

    const getCurName = (id) => {
        const index = dpOptions.findIndex(option => option.value === id);
        if (index > -1) {
            return dpOptions[index]?.label || '-';
        } else {
            return '-';
        }
    }

    const columns = [
        {
            title: '店铺名称',
            dataIndex: '',
            render: (cur) => (
                <div>
                    <span>{getCurName(cur.seller_id)}</span>
                </div>
            ),
            width: 90
        },
        {
            title: '货件编号',
            dataIndex: 'inboundId',
            sorter: (a, b) => a.inboundId - b.inboundId,
            width: 90
        },
        {
            title: '最后更新时间 (GMT-4)',
            dataIndex: 'last_updated',
            sorter: (a, b) => new Date(a.last_updated).getTime() -  new Date(b.last_updated).getTime(),
            render: (date) => (
                <div>
                    <span>{getDate(date)}</span>
                    <br/>
                    <span>{getTime(date)}</span>
                </div>
                
            ),
            width: 100
        },
        {
            title: '申报件数',
            dataIndex: 'products_count',
            sorter: (a, b) => a.products_count -  b.products_count,
            width: 90
        },
        {
            title: '货件状态  (GMT-6)',
            dataIndex: '',
            render: (cur) => (
                <span>{getCoverdStatus(cur.status, cur.appointment)}</span>
            ),
            width: 80
        },
        {
            title: '约仓规则',
            dataIndex: '',
            key: '',
            render: (cur) => (
                <Setting
                    key={cur.key}
                    cur={cur}
                    status={getStatus(cur?.inboundId)}
                    rules={cur.rules}
                    selectedRuleType={selectedRule[cur.inboundId]}
                    selectRule={(rule_type, inboundId) => selectRule(rule_type, inboundId)}
                    applying={getApplyStatus(cur?.inboundId)}
                    saveRule={(values) => setRule(values, cur?.inboundId)}>
                </Setting>),
                width: 160
        },
        {
            title: '货运设置',
            dataIndex: '',
            render: (cur) => (
                <AddressModal key={cur.key} status={getStatus(cur?.inboundId)} applying={getApplyStatus(cur?.inboundId)} data={cur.addressInfo} current={cur.current} saveAddress={(data) => saveAddress(cur, data)}></AddressModal> 
            ),
            width: 200
        },
        {
            title: '约仓状态',
            dataIndex: '',
            key: '',
            render: (cur) => (
                <Apply 
                    key={cur.key}
                    cur={cur}
                    rules={cur.rules}
                    appointment={cur.appointment}
                    applying={getApplyStatus(cur?.inboundId)}
                    selectedRuleType={selectedRule[cur.inboundId]}
                    status={getStatus(cur?.inboundId)?.status}
                    startTime={getStatus(cur?.inboundId)?.begin_book_time}
                    successTime={cur.successTime}
                    applyStart={() => applyStart(cur)}
                    applyStop={() => applyStop(cur)}
                    saveRule={(values, id) => setRule(values, id)}
                    points={points}>
                </Apply>),
                shouldCellUpdate: () => {
                    return true
                },
            width: 180
        },
        {
            title: '预约时间分布图',
            dataIndex: '',
            key: '',
            render: (cur) => {
                const items = getStatus(cur?.inboundId)?.history_time || [];
                const formDays = items.map(item => {
                    const str = item.from_day;
                    const day = getDate(str)?.slice(5);
                    const time = getTime(str)?.split(':')[0];
                    const data = getTimeRange(time);
                    return {
                        data,
                        day
                    }
                }).sort((a, b) => {
                    return a?.data?.localeCompare(b?.data)
                });

                const config = {
                    data: formDays,
                    xField: 'day',
                    yField: 'data',
                    size: 6,
                    shape: 'circle',
                    tooltip: {
                        formatter: (datum) => {
                          return { name: `日期：${datum.day}`, value: '可预约时间段：' + datum.data};
                        },
                    },
                    xAxis: {
                        nice: true,
                        grid: {
                          line: {
                            style: {
                              stroke: '#eee',
                            }
                          }
                        }
                      },
                    yAxis: {
                      nice: true,
                      line: {
                        style: {
                          stroke: '#aaa',
                        },
                      },
                    },
                  };

                  if(formDays.length) {
                    return (
                        <div>
                            <Button type="link" block onClick={showModal}>
                                查看
                            </Button>
                            <Modal width={"100%"}  maskStyle={{ "background":"none"}} title="可预约时间分布图" footer={null} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>

                                <Tooltip  title="从点击预约按钮开始，曾经出现过的可预约的时间，但不表示当前仍可以使用该预约时间，该图用来表明当下哪些时间段是比较容易预约成功的。">
                                    <InfoCircleOutlined />
                                </Tooltip>
                                <Scatter {...config} />
                            </Modal>
                        </div>
                      )
                  } else {
                    return '-'
                  }
            },
            width: 100
        },
    ];

    const handleTableChange = (pagination) => {
        const { current , pageSize} = pagination
        const startIndex = (current - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const data1 = inboundsFilter.slice(startIndex, endIndex).map(item => item.inboundId)
        setIds([...data1])
    }

    return (
        <div className='home'>
            <div style={{"marginBottom": "1em","display": "flex","justifyContent": "space-between"}}>
                <Space>
                <Select
                    style={{
                        width: '13em',
                    }}
                    placeholder="选择店铺"
                    defaultValue={'all'}
                    onChange={handleDpChange}
                    options={dpOptions}
                    />
                    <Divider type="vertical" />
                    <Input disabled={tableLoading} placeholder="过滤货件编号" onChange={onFilterValueChange} allowClear value={filterValue}/>
                </Space>
                <Space>
                    <Button style={{"marginLeft":"10em"}} icon={<CloudDownloadOutlined />} onClick={exportTableJSON} disabled={tableLoading} loading={exportLoading} >导出货件SKU</Button>
                    <Button icon={<SettingOutlined />} onClick={showRuleModal} disabled={tableLoading}>设置默认规则</Button>
                    <Button icon={<SendOutlined />} onClick={applySelect} disabled={points <= 0 || tableLoading || shouldApplyAllEnable()} loading={applyAllOngoing}>
                        一键约仓
                    </Button>
                    <Button type="primary" icon={<RedoOutlined />} onClick={forceUpdated} loading={forceUpdating} disabled={tableLoading || forceUpdating}>
                        更新货件
                    </Button>
                    
                    <RuleModal open={isRuleModalOpen} onClose={onRuleClose} data={defaultRule} saveRule={(value) => setRule(value)}></RuleModal>
                </Space>
            
            </div>
            <div style={{"marginBottom": "1em","display": "flex","justifyContent": "space-between"}}>
                <Space>
                    <label>货件状态：</label>
                        <Checkbox.Group options={statusValues} value={statusList} defaultValue={['Idle', 'Ongoing', 'Succeeded']} onChange={onStatusFilterChange} disabled={tableLoading} />
                </Space>
            </div>
             <Table 
                loading={tableLoading} 
                columns={columns} 
                dataSource={inboundsFilter} 
                rowSelection={rowSelection} 
                size="middle" 
                scroll={{ x: 'max-content', y: '80%' }} 
                onChange={handleTableChange}
            /> 
        </div>
    )
}

export default Main
