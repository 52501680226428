import React from "react";
import { Modal, Button, Space, Tag, Popover } from 'antd'
import { SettingFilled, SmileTwoTone, SyncOutlined, CloseOutlined } from '@ant-design/icons';
import { useState } from 'react';
import RuleModal from './ruleModal'

function getBtName(status) {
    if (status === 'Idle' || status === 'Edle') {
        return '预约';
    } else if (status === 'Succeeded') {
        return '预约成功';
    }
    return '取消预约'
}

const config = {
    title: '账户已欠费!',
    maskClosable: true,
    closeIcon: (<CloseOutlined />),
    content: (
      <div>
            <span>您当前的点数不足，请联系客服人员充值！</span>
            <br/>
            <span>客服微信号/手机号： 18626896818</span>
      </div>
    ),
    footer: null
  };

const Apply = (props) => {
    const rules = props.rules;
    const applyAll = props.applying;
    const appointment = props.appointment;
    const rule_type = props.cur.rule_type;
    const point = props.cur.point;
    const selectedRuleType = props.selectedRuleType || rule_type;
    const currentRule = rules[selectedRuleType - 1]
    const [hovered, setHovered] = useState(false);
    const [applying, setApplying] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isQFModalOpen, setIsQFModalOpen] = useState(false);
    const [isRuleModalOpen, setIsRuleModalOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modal, contextHolder] = Modal.useModal();
    const showModal = () => {
        if (!(props.points <= 0)) {
            setIsModalOpen(true);
        } else {
            modal.confirm(config);
        }
    };

    const onClose = () => {
        setIsQFModalOpen(false)
    }
    const handleOk = () => {
        apply();
    }
    const handleCancel= () => {
        setIsModalOpen(false)
    }
    let shouldDisabled = applying || props.status === 'Succeeded' || (appointment && appointment.appointment_expired !== true);
    const btName = getBtName(props.status);

    const apply = () => {
        setIsModalOpen(false)
        setApplying(true)
        props.applyStart().then(() => {
            setApplying(false)
        })
    };

    const stop = () => {
        setApplying(true)
        props.applyStop().then(() => {
            setApplying(false)
        })
    }

    const onRuleClose = () => {
        setIsRuleModalOpen(false)
    }

    const onRuleOpen = () => {
        setIsRuleModalOpen(true)
    }

    const saveRule = (data) => {
        const inbound_id = currentRule.name === '自定义规则' ? props.cur?.inboundId : null
        return props.saveRule(data, inbound_id)
    }

    const handleHoverChange = (open) => {
        setHovered(open);
    };

    const coverValue = (value) => {
        if (value === 'pickup') {
            return 'Quiero la colecta a domicilio'
        } else if (value === 'ftl' ) {
            return 'lre en um vehicule particular'
        }
        return value;
    }

    return (
        <div className='home'>
            <Space>
                {props.status === 'Ongoing' && <div><SyncOutlined spin /><span> 预约中 ...</span></div>}
                {(props.status === 'Idle' ||props.status === 'Edle')  && <Button type="primary" size="small" onClick={showModal} loading={applying || applyAll} disabled={shouldDisabled}>
                    {btName}
                </Button>}
                {props.status === 'Ongoing' && <Button type="primary" size="small" onClick={stop} loading={applying} disabled={shouldDisabled}>
                        {btName}
                    </Button>}
            </Space>
            <br/>
            {!!props.successTime && <SmileTwoTone twoToneColor="#52c41a"/>}
            <br/>
            {!!props.startTime && <span style={{"fontSize":"11px","color":"#ccc"}}>{`开始于： ${props.startTime}`}</span>}
            <br/>
            {!!props.successTime && <span style={{"fontSize":"11px","color":"#ccc"}}>{`成功于： ${props.successTime}`}</span>}
            {props.status === 'Ongoing' && <Popover
                style={{ maxWidth: '300px' }}
                content={
                    <Space direction="vertical">
                        <div><label style={{"fontWeight": "800"}}>规则名称：</label><label>{<Tag bordered={false}>{currentRule.name}</Tag>}</label></div>
                        <div><label style={{"fontWeight": "800"}}>期望时间范围: </label>
                            <Tag bordered={false}>{currentRule.start_time}</Tag> - <Tag bordered={false}>{currentRule.stop_time}</Tag>
                        </div>
                        <div><label style={{"fontWeight": "800"}}>期望周几约到：</label>
                            {currentRule.weekly.map((item, index) => (
                                <Tag key={index} bordered={false}>{item}</Tag>
                            ))}
                        </div>
                        <div><label style={{"fontWeight": "800"}}>期望预约时间段：</label>
                            {currentRule.TimeRanges.map((item, index) => (
                                <Tag key={index} bordered={false}>{item}</Tag>
                            ))}
                        </div>
                        {currentRule.special?.length > 0 && <div>
                            <label style={{"fontWeight": "800"}}>特殊规则：</label><label>{currentRule.special ? <Tag bordered={false}>{currentRule.special}</Tag> : "无"}</label>
                        </div>}
                    </Space>
                }
                title="当前规则信息："
                trigger="hover"
                open={hovered}
                onOpenChange={handleHoverChange}
            >
                <Button icon={<SettingFilled />} />
            </Popover>}
            <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel} destroyOnClose={true} confirmLoading={confirmLoading} cancelText={"取消"} okText={"预约"} width={600}>
        
                <label style={{"fontWeight": "800", "fontSize": "17px"}}>请确认预约信息如下：</label>
                {currentRule &&  <div style={{"marginTop": "20px"}}>
                <Space direction="vertical">
                    <label style={{"fontWeight": "800"}}>约仓规则信息：</label>
                    <div><label style={{"fontWeight": "800"}}>规则名称：</label><label>{currentRule.name}</label></div>
                    <div><label style={{"fontWeight": "800"}}>期望时间范围: </label><label> {currentRule.start_time} - {currentRule.stop_time} </label></div>
                    <div><label style={{"fontWeight": "800"}}>期望周几约到：</label><label>{currentRule.weekly?.join(', ')}</label></div>
                    <div><label style={{"fontWeight": "800"}}>期望预约时间段：</label><label>{currentRule.TimeRanges?.join(', ')}</label></div>
                    {currentRule.special?.length > 0 && <div>
                        <label style={{"fontWeight": "800"}}>特殊规则：</label><label>{currentRule.special || "无"}</label>
                        <Tag>2023-09-16</Tag><Tag>2023-11-20</Tag><Tag>2023-12-25</Tag>
                        <Tag>2024-01-01</Tag><Tag>2024-02-05</Tag><Tag>2024-03-18</Tag>
                        <Tag>2024-05-01</Tag><Tag>2024-06-02</Tag><Tag>2024-09-16</Tag>
                        <Tag>2024-10-01</Tag><Tag>2024-11-18</Tag><Tag>2024-12-25</Tag>
                    </div>}
                    <div><label style={{"fontWeight": "800"}}>成功预约所需点数：</label><label>{point ? point : '-'}</label></div>
                    <Button size="small" onClick={onRuleOpen}>
                        修改当前规则
                    </Button>
                </Space>
                </div>}

                <RuleModal open={isRuleModalOpen} onClose={onRuleClose} data={currentRule} saveRule={(value) => saveRule(value)}></RuleModal>
            </Modal>
            {contextHolder}
          </div>
    )
}

export default Apply
