import React from "react";
import { Button, Space, Switch } from 'antd';
import { useState } from 'react';
import RuleModal from './ruleModal'

const Setting = (props) => {
    const rules = props.rules;
    const rule_type = props?.selectedRuleType || props.cur?.rule_type || 1;

    const modalData = rules[rule_type - 1];
    const isCheck = modalData?.name === '自定义规则';
    const shouldDisabled = props.applying || props.status?.status !== 'Idle' || !modalData;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [checked, setChecked] = useState(isCheck);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const saveRule = (data) => {
        return props.saveRule(data);
    }

    const onClose = () => {
        setIsModalOpen(false);
    }

    const isChecked = () => {
        return modalData.name === '自定义规则';
    }

    const onChange = (checked) => {
        setChecked(checked);
        const rule_type = checked ? 2 : 1;
        props.selectRule(rule_type, props.cur?.inboundId);
    }

    return (
        <div>
            <Space>
                <Switch checkedChildren="自定义" unCheckedChildren="默认" defaultChecked={isCheck} onChange={onChange} disabled={shouldDisabled}></Switch>
                {checked && <span>
                    <Button size="small" onClick={showModal} disabled={shouldDisabled}>设置</Button>
                    <RuleModal open={isModalOpen} onClose={onClose} data={modalData} saveRule={(value, isDefault) => saveRule(value, isDefault)}></RuleModal>
                </span>}
            </Space>
        </div>
    )
}  

export default Setting
