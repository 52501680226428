import { Button, Card , Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
const pre = '/book_api_v2';
import { useState, useEffect } from 'react'; 
 
const loginRequest = (data) => {
  return fetch(`${pre}/Login`, {
     method: 'POST',
     body: JSON.stringify(data),
     headers: {
        'Content-type': 'application/json; charset=UTF-8',
     },
  })
  .then(rep => {
      if (rep.status === 200) {
          return rep.json().then(res => {
            if (res.status === 404) {
              return Promise.reject(res)
            } else {
              return res;
            }
          })
      } else {
          return Promise.reject(rep)
      }
  })
};

const Login = () => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const onFinish = (values) => {
      setError('')
      setLoading(true)
        return loginRequest(values).then((resp) => {
          if (resp === 'user name or password error') {
            setLoading(false);
            setError('用户名或者密码错误！');
          } else {
            window.sessionStorage.setItem('token', resp.data.token);
            window.sessionStorage.setItem('user', JSON.stringify(resp.data.user));
            window.sessionStorage.setItem('sellers', JSON.stringify(resp.data.sellers));
            setLoading(false);
            navigate('/home');
          }
        }).catch((res) => {
          setLoading(false)
          setError(res? res.message : 'Login failed')
        })
      };
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };
    return (
        <Card title="捷豹约仓助手 - 美客多版" headStyle={{fontSize:'20px'}}  bordered={false} style={{ width: 600, margin: '250px auto', backgroundColor: 'rgba(255, 255, 255, .3)'}}>
    <Form
          validateTrigger={['onBlur', 'onChange']}
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
            marginRight: '85px'
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="用户名"
            name="username"
            rules={[
              {
                required: true,
                message: '请输入用户名!',
                validateTrigger: 'onBlur'
              },
            ]}
          >
            <Input />
          </Form.Item>
      
          <Form.Item
            label="密码"
            name="password"
            rules={[
              {
                required: true,
                message: '请输入密码!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
      
          {/* <Form.Item
            name="remember"
            valuePropName="checked"
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Checkbox>Remember me</Checkbox>
          </Form.Item> */}
      
          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit" disabled={loading} loading={loading}>
              登录
            </Button>
            <br/>
            {error ? <span style={{"color":"red"}}>   {error}</span> : ''}
          </Form.Item>
        </Form>
  </Card>

        
      );
} 
export default Login;
